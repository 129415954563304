@import '../../variables.scss';

.wrapper {
    position: relative;
}

.progresso-circular {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 13px solid $amber-color;
    border-top: 5px solid transparent;
    animation: girar 1.5s linear infinite;
    margin-bottom: 28px;
}

.aguarde {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-family: Arial, sans-serif;
    font-size: 24px;
    color: #fff;
    font-weight: bold;
}

@keyframes girar {
    to {
        transform: rotate(360deg);
    }
}