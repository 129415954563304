@import "../../variables.scss";

.list-container {
  max-width: 100%;
  margin: 20px auto;
  padding-top: 80px;
}

.list-item {
  display: flex;
  align-items: center;
  padding: 13px;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
}

.list-item:hover {
  background-color: $amber-color;
}


.icon {
  margin-right: 10px;
  font-size: 24px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.subtitle {
  margin-top: 10px;
  font-size: 14px;
  color: #fff;
}

.arrow {
  margin-left: auto;
  color: $amber-color;
}

.arrow:hover {
  color: #fff;
}

.imagem-circular {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
}

.imagem-circular-logo {
  width: 100%;
  height: 100%;
  object-fit: inherit;
  border-radius: 50%;
}

.logo-product {
  width: 100%;
  height: 100%;
  object-fit: inherit !important;
  border-radius: 50%;
}

.header-serach-partner {
  background-color: #1d1f20;
  padding: 1%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  padding: 30px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}


.partner-header {
  height: 200px !important;
  width: 100% !important;
  background-color: #1d1f20;
  color: #fff;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.partner-info {
  color: #fff;
  text-align: center;
  padding-top: 10px;
}

.amount {
  font-size: 20px;
}